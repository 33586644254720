.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #414141;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.Mockups{
  height: 35vmin;
  margin-bottom: 1em;
}
.App-Preview{
  height: 15vmin;
  display: flex;
  flex-direction: column;
}
.Mac{
  height: 30vmin;
  margin-bottom: 1em;
}
.Previews{
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}
.Nav-Logo{
  margin-right: 1em;
}